var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[(_vm.userAccess.canUpdate)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":_vm.updateInvoice}},[_vm._v(" "+_vm._s(_vm.$_strings.invoice.TITLE_EDIT)+" ")]):_vm._e(),(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.navigateToAddInvoice}},[_vm._v(" "+_vm._s(_vm.$_strings.invoice.ADD_ORDER)+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': _vm.$_item_per_page
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.shipmentTitle",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shipmentTitle))]),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.shipmentCreatedDate))])]}},{key:"item.grandTotalAmount",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.grandTotalAmount)))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.typePrice))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function (){ return _vm.showDialogCalculateCost(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"red"},on:{"click":[function () { return _vm.confirmDeleteInvoice(item); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.INVOICE_LIST)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1),_c('CalculateCost',{ref:"calculateCost"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }