<template>
  <div>
    <v-row v-for="(mainCost, index) in itemsMainCost" :key="index">
      <v-col cols="12" sm="8">
        <h2 class="caption">{{typeCost(index, mainCost.costName)}}</h2>
        <div class="d-flex">
          <v-text-field
            outlined
            dense
            disabled
            v-model="mainCost.quantity"
            hide-details
          ></v-text-field>
          <v-select
            v-if="mainCost.costName && mainCost.costName !== 'MULTIPICK' && mainCost.costName !== 'MULTIDROP'"
            v-model="mainCost.costName"
            :items="itemsTypeCost"
            hide-details
            dense
            outlined
            disabled
          >
          </v-select>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <h2
          v-if="typeCost(index, mainCost.costName)"
          class="caption"
        >
          {{$_strings.order.AMOUNT_OF_COSTS}}
        </h2>
        <common-text-field-currency
          dense
          disabled
          outlined
          v-model="form.totalPrice"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { convertTextTypeCost } from '@/helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    shipmentId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      itemsMainCost: [],
      itemsTypeCost: [],
    };
  },
  methods: {
    typeCost(index, type) {
      if (type.toLowerCase() === 'multipick' || type.toLowerCase() === 'multidrop') return `${type} (Rp)`;
      if (index > 0) return null;
      return this.$_strings.order.MAIN_COST;
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const resultMainCost = await this.$_services.order.getMainCost(this.shipmentId);
        if (resultMainCost.data.mainCost) {
          this.form.totalPrice = resultMainCost.data.mainCost.reduce((curr, val) => curr + val.totalPrice, 0);
          this.itemsMainCost = resultMainCost.data.mainCost;
          this.itemsTypeCost = resultMainCost.data.mainCost.map((cost) => ({
            value: cost.costName,
            text: convertTextTypeCost(cost.costName),
          }));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
