<template>
  <v-container fluid>
    <v-sheet class="pa-2">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="userAccess.canUpdate"
            class="mr-2"
            color="primary"
            small
            @click="updateInvoice"
          >
            {{$_strings.invoice.TITLE_EDIT}}
          </v-btn>
          <v-btn
            v-if="userAccess.canCreate"
            color="primary"
            outlined
            small
            @click="navigateToAddInvoice"
          >
            {{$_strings.invoice.ADD_ORDER}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :loading="isLoading"
            :headers="displayedHeader"
            :items="items"
            :server-items-length="totalData"
            :options.sync="pagination"
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              'items-per-page-options': $_item_per_page
            }"
          >
            <template v-slot:[`item.shipmentTitle`]={item}>
              <span>{{ item.shipmentTitle }}</span>
              <br/>
              <span class="caption">{{ item.shipmentCreatedDate }}</span>
            </template>
            <template v-slot:[`item.grandTotalAmount`]={item}>
              <span>{{ formatMoney(item.grandTotalAmount) }}</span>
              <br/>
              <span class="size-12">{{ item.typePrice }}</span>
            </template>
            <template v-slot:[`item.action`]={item}>
              <v-tooltip top v-if="userAccess.canUpdate">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    icon
                    class="mr-2"
                    color="primary"
                    @click="()=> showDialogCalculateCost(item)"
                  >
                    <v-icon> mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.EDIT}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="userAccess.canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    icon
                    color="red"
                    @click="() => confirmDeleteInvoice(item)"
                    @click.stop.prevent
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.DELETE}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="items.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <CalculateCost
      ref="calculateCost"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  formatMoney,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import CalculateCost from '../Dialog/CalculateCost';

export default {
  components: {
    CalculateCost,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'shipperName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.REKANAN_3PL,
          value: 'transporterAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.QUANTITY,
          value: 'quantity',
          class: 'white--text primary text-capitalize',
          align: 'center',
        },
        {
          text: this.$_strings.invoice.UNIT_PRICE,
          value: 'priceUnit',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'grandTotalAmount',
          class: 'white--text primary text-capitalize',
          align: 'center',
        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'action',
          class: 'white--text primary text-capitalize',
          align: 'center',
          sortable: false,
        },
      ],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate, canDelete } = this.userAccess;
      return this.headers.filter((header) => {
        if (!canUpdate && !canDelete) {
          return header.value !== 'action';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    formatMoney,
    navigateToAddInvoice() {
      const {
        dateFrom,
        dateTo,
        companyName,
        companyId,
      } = this.$route.query;
      const { invoiceId } = this.$route.params;
      this.$router.push({
        name: 'invoice-cancel-create',
        params: {
          invoiceId,
        },
        query: {
          dateFrom,
          dateTo,
          companyName,
          companyId,
        },
      });
    },
    showDialogCalculateCost(item) {
      this.$refs.calculateCost.orderSelected = {
        invoiceId: item.id,
        shipmentId: item.shipmentId,
      };
      this.$refs.calculateCost.dialog = true;
    },
    async confirmDeleteInvoice(item) {
      const userRes = await this.$dialog.warning({
        text: 'Apakah anda yakin ingin menghapus ini?',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      this.deleteInvoice(item);
    },
    async updateInvoice() {
      const { invoiceId } = this.$route.params;
      try {
        this.$root.$loading.show();
        await this.$_services.invoiceCustomerCancel.updateInvoice(invoiceId);
        this.$dialog.notify.success('Berhasil memperbarui invoice');
        this.fetchData();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async deleteInvoice(item) {
      const { invoiceId } = this.$route.params;
      const { shipmentId } = item;
      try {
        this.$root.$loading.show();
        await this.$_services.invoiceCustomerCancel.deleteInvoice(invoiceId, shipmentId);
        this.$dialog.notify.success('Berhasil menghapus invoice');
        this.fetchData();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const { invoiceId } = this.$route.params;
        const filter = skipEmptyStringObject({
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        const result = await this.$_services.invoiceCustomerCancel.fetchListDetail(invoiceId, filter);
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
